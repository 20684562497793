import { faArrowDownToBracket, faFolderArrowUp, faLock, faTrashCan, faChartSimple, faCircleInfo, faPuzzle, } from 'src/application/icons/fontAwesome/light';
export const informationContentItems = {
    analytics: {
        icon: faChartSimple,
    },
    additional_content: {
        icon: faPuzzle,
    },
    detail: {
        icon: faCircleInfo,
    },
    move_to: {
        icon: faFolderArrowUp,
    },
    restrictions: {
        icon: faLock,
    },
    download: {
        icon: faArrowDownToBracket,
    },
    delete: {
        icon: faTrashCan,
    },
};
