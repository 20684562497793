import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectEventListeners } from '../../Select/hooks/useSelectEventListeners';
export function SelectMultiple(props) {
    const selectMultipleRef = useRef(null);
    const { t } = useTranslation();
    const onChange = useCallback((e) => {
        props?.onChange?.(e.detail);
    }, [props]);
    useSelectEventListeners(selectMultipleRef, {
        onChange: onChange,
        onFocus: props.onFocus,
        onOpen: props.onOpen,
        onClose: props.onClose,
    });
    useEffect(() => {
        if (props.disableSelectAll && selectMultipleRef.current) {
            const shadowRoot = selectMultipleRef.current.shadowRoot;
            if (shadowRoot) {
                const elementToRemove = shadowRoot.querySelector('hot-dropdown hot-select-multiple-option');
                elementToRemove?.remove();
            }
        }
    }, [props.disableSelectAll]);
    return (_jsx("hot-select-multiple", { id: props.id, ref: selectMultipleRef, autocomplete: props.autoComplete ? 'on' : 'off', placeholder: props.placeholder, size: props.size, "text-no-results": props.textNoResults, "text-select-all": props.textSelectAll, "text-selected": t('general.selected'), class: props.className, "aria-label": props.ariaLabel, ...(props.invalid && { invalid: true }), ...(props.loading && { loading: true }), ...(props.disabled && { disabled: true }), "data-testid": 'hot-select-multiple', children: props.children }));
}
