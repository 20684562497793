import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { useTranslation } from 'react-i18next';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon, faXmark, faPuzzle, } from 'src/application/icons/fontAwesome/light';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useState } from 'react';
import { SubtitleBatchContainer } from 'src/domain/Media/modules/Subtitle/containers/SubtitleBatchContainer';
import styles from './styles.module.scss';
import { additionalContentItems } from './constants';
import { Home } from './Home';
var ActiveComponent;
(function (ActiveComponent) {
    ActiveComponent["HOME"] = "HOME";
    ActiveComponent["SUBTITLE"] = "SUBTITLE";
})(ActiveComponent || (ActiveComponent = {}));
export const AdditionalContentModal = NiceModal.create(({ onDismiss, medias }) => {
    const modal = useModal();
    const { t } = useTranslation();
    const [activeComponent, setActiveComponent] = useState(ActiveComponent.HOME);
    additionalContentItems['subtitle'].onClick = () => {
        setActiveComponent(ActiveComponent.SUBTITLE);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_OPEN_SUBTITLES, 'button', [
            {
                key: 'mediaCodes',
                value: medias.length.toString(),
            },
        ]);
    };
    return (_jsxs(Drawer, { onCloseDrawer: () => {
            modal.remove();
            onDismiss?.();
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_MODAL_CLOSE, 'button', [
                {
                    key: 'view',
                    value: activeComponent,
                },
            ]);
        }, dismissible: false, opened: modal.visible, className: styles.modal, position: 'bottom', children: [_jsx("div", { slot: 'heading', children: _jsxs("header", { "data-testid": 'additional-content-modal-header', className: styles.heading, children: [_jsxs("div", { className: styles.headerContent, children: [_jsx("span", { className: styles.iconTitleModal, children: _jsx(FontAwesomeIcon, { icon: faPuzzle }) }), _jsx(Heading, { type: 'h4', size: 'small', className: styles.modalTitle, children: t('additional_content.modal.header_title') })] }), _jsx(Button, { variation: 'tertiary', className: styles.closeButton, onClick: () => {
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_MODAL_CLICK_CLOSE_BUTTON, 'button', [
                                    {
                                        key: 'view',
                                        value: activeComponent,
                                    },
                                ]);
                                modal.hide();
                            }, children: _jsx(FontAwesomeIcon, { icon: faXmark }) })] }) }), activeComponent === ActiveComponent.HOME && (_jsx(Home, { additionalContentItems: additionalContentItems })), activeComponent === ActiveComponent.SUBTITLE && (_jsx(SubtitleBatchContainer, { previousPage: () => setActiveComponent(ActiveComponent.HOME), medias: medias, onSubmitSubtitleBatch: () => modal.hide() })), _jsx("div", { slot: 'controls' })] }));
});
