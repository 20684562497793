import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon, faCircleInfo, } from 'src/application/icons/fontAwesome/light';
import { Button } from 'src/ui/cosmosWeb/Button';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { LibraryContentFilter } from '../../containers/LibraryContent/LibraryContentFilter';
import { UploadAction } from '../UploadAction';
import { LibraryFeaturesInfoDrawer } from '../Modal/LibraryFeaturesInfoDrawer';
import styles from './styles.module.scss';
export const Header = ({ uploadButton = true }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.contentTitle, children: [_jsxs("div", { className: styles.titleContainer, children: [_jsx(Heading, { type: 'h2', className: styles.title, children: t('library.title') }), _jsx(Button, { className: styles.buttonInfo, variation: 'tertiary', onClick: () => {
                                    NiceModal.show(LibraryFeaturesInfoDrawer);
                                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_INFORMATION_MODAL_OPEN);
                                }, children: _jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faCircleInfo }) }) })] }), uploadButton && (_jsx(UploadAction, { dropdownButton: {
                            className: styles.dropdownButton,
                            label: t('general.new'),
                        } }))] }), _jsx(LibraryContentFilter, {})] }));
};
