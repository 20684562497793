import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { Trans, useTranslation } from 'react-i18next';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { Drawer } from 'src/ui/cosmosWeb/Drawer';
import { FontAwesomeIcon, faCircleInfo, } from 'src/application/icons/fontAwesome/light';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Alert } from 'src/ui/cosmos/Alert';
import additionalContentGif from 'public/assets/gif/additional_content.gif';
import styles from './styles.module.scss';
import { informationContentItems } from './constants';
export const LibraryFeaturesInfoDrawer = NiceModal.create(() => {
    const modal = useModal();
    const { t } = useTranslation();
    return (_jsxs(Drawer, { onCloseDrawer: () => {
            modal.remove();
            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_INFORMATION_MODAL_CLOSE);
        }, opened: modal.visible, className: styles.container, children: [_jsx("div", { slot: 'heading', children: _jsx(Heading, { type: 'h4', size: 'small', className: styles.headerTitle, children: _jsx(Trans, { i18nKey: 'library_feature_information.modal.header_title', components: { br: _jsx("br", {}) } }) }) }), _jsx("div", { slot: 'content', children: _jsxs("div", { className: styles.content, children: [_jsx("p", { className: styles.description, children: t('library_feature_information.modal.description') }), _jsx("img", { loading: 'lazy', src: additionalContentGif, alt: '', className: styles.imageInfo }), _jsxs(Alert, { className: `hot-alert--info ${styles.alert}`, children: [_jsx(FontAwesomeIcon, { icon: faCircleInfo, size: 'lg' }), _jsx("span", { children: t('library_feature_information.modal.alert') })] }), _jsx("strong", { children: t('library_feature_information.modal.options.title') }), _jsx("ul", { className: styles.optionsList, children: Object.keys(informationContentItems).map((key) => (_jsxs("li", { children: [_jsx("span", { className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: informationContentItems[key].icon }) }), _jsx("p", { children: _jsx(Trans, { i18nKey: `library_feature_information.modal.options.items.${key}`, components: { strong: _jsx("strong", {}) } }) })] }, key))) })] }) })] }));
});
