// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.av16oj7IQZO6ZUMc7TuA{border-radius:var(--hc-size-border-large);height:4.375rem}.av16oj7IQZO6ZUMc7TuA .X411ancshCDevebZN8KL{border-radius:var(--hc-size-border-large);width:100%}.av16oj7IQZO6ZUMc7TuA:last-child{margin-bottom:0 !important}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Subtitle/containers/SubtitleBatchItems/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CACA,eAAA,CAEA,4CACE,yCAAA,CACA,UAAA,CAGF,iCACE,0BAAA","sourcesContent":[".info {\n  border-radius: var(--hc-size-border-large);\n  height: 4.375rem;\n\n  .box {\n    border-radius: var(--hc-size-border-large);\n    width: 100%;\n  }\n\n  &:last-child {\n    margin-bottom: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `av16oj7IQZO6ZUMc7TuA`,
	"box": `X411ancshCDevebZN8KL`
};
export default ___CSS_LOADER_EXPORT___;
