import { ImportProvider } from 'src/domain/Media/components/UploadAndImport/ImportMediaProvider/constants';
export var IntentEvent;
(function (IntentEvent) {
    /** @general */
    IntentEvent["GENERAL_LOGOUT"] = "logout";
    IntentEvent["SHOW_ALL"] = "show.all";
    IntentEvent["MENU_TOOLS"] = "menu.tools";
    /** @home */
    IntentEvent["HOME_NEW_FOLDER"] = "home.new.folder";
    IntentEvent["HOME_NEW_UPLOAD_NEW_USER"] = "home.new.upload.new.user";
    IntentEvent["HOME_NEW_UPLOAD_HEAVY_USER"] = "home.new.upload.heavy.user";
    IntentEvent["HOME_NEW_IMPORT"] = "home.new.import";
    IntentEvent["HOME_SHOW_ADVANTAGES_HOTMART_PLAYER"] = "home.show.advantages.hotmart.player";
    IntentEvent["HOME_CLOSE_ADVANTAGES_HOTMART_PLAYER"] = "home.close.advantages.hotmart.player";
    IntentEvent["ADVANTAGES_HOTMART_PLAYER_TAB_ADVANTAGES_FOR_YOU"] = "home.tab.advantages.for.you";
    IntentEvent["ADVANTAGES_HOTMART_PLAYER_TAB_ADVANTAGES_FOR_YOUR_STUDENTS"] = "home.tab.advantages.for.your.students";
    IntentEvent["HOME_CREATE_FIRST_FOLDER"] = "home.create.first.folder";
    IntentEvent["HOME_VIEW_ALL_FOLDERS"] = "home.view.all.folder";
    IntentEvent["HOME_VIEW_ALL_MEDIA"] = "home.view.all.media";
    IntentEvent["LIMIT_EXCEEDED_WARNING"] = "plan.limit.exceeded";
    IntentEvent["HOME_SLIDE_ITEM_PREFIX"] = "home.slider.item";
    /** @library */
    IntentEvent["LIBRARY_DELETE_MIXED_ITEMS_FROM_LIBRARY_CONFIRMATION"] = "delete.mixed.items";
    IntentEvent["LIBRARY_SELECT_ONE_ITEM"] = "select.one.item";
    IntentEvent["LIBRARY_UNSELECT_ONE_ITEM"] = "unselect.one.item";
    IntentEvent["LIBRARY_SELECT_MULTIPLE_ITEMS"] = "select.multiple.items";
    IntentEvent["LIBRARY_UNSELECT_MULTIPLE_ITEMS"] = "unselect.multiple.items";
    IntentEvent["LIBRARY_CLEAR_SELECTION"] = "clear.selection";
    IntentEvent["LIBRARY_SEARCH"] = "search";
    IntentEvent["LIBRARY_NEW_UPLOAD"] = "new.upload";
    IntentEvent["LIBRARY_ITEM_MENU_SETTINGS"] = "item.menu.settings";
    IntentEvent["LIBRARY_SORT_ITEM_BY_NAME"] = "sortby.name";
    IntentEvent["LIBRARY_LINK_ARTICLE_HELP_CLICK"] = "link.library.article.help.click";
    /** @libraryMedia */
    IntentEvent["LIBRARY_DELETE_MEDIA_CONFIRMATION"] = "delete.media.confirmation";
    IntentEvent["LIBRARY_MOVE_MEDIA"] = "move.media";
    IntentEvent["LIBRARY_RENAME_MEDIA"] = "rename.media";
    IntentEvent["LIBRARY_ITEM_MENU_MEDIA"] = "item.menu.media";
    IntentEvent["LIBRARY_ITEM_MENU_MOVE_MEDIA"] = "item.menu.move.media";
    IntentEvent["LIBRARY_ITEM_MENU_SHARE_MEDIA"] = "item.menu.share.media";
    IntentEvent["LIBRARY_ITEM_MENU_RENAME_MEDIA"] = "item.menu.rename.media";
    IntentEvent["LIBRARY_ITEM_MENU_SETTINGS_MEDIA"] = "item.menu.settings.media";
    IntentEvent["LIBRARY_ITEM_MENU_DOWNLOAD_MEDIA"] = "item.menu.download.media";
    IntentEvent["LIBRARY_ITEM_MENU_DELETE_MEDIA"] = "item.menu.delete.media";
    /** @libraryFolder */
    IntentEvent["LIBRARY_DELETE_FOLDER_CONFIRMATION"] = "delete.folder.confirmation";
    IntentEvent["LIBRARY_RENAME_FOLDER"] = "rename.folder";
    IntentEvent["LIBRARY_NEW_FOLDER"] = "new.folder";
    IntentEvent["LIBRARY_ITEM_MENU_FOLDER"] = "item.menu.folder";
    IntentEvent["LIBRARY_ITEM_MENU_RENAME_FOLDER"] = "item.menu.rename.folder";
    IntentEvent["LIBRARY_ITEM_MENU_DELETE_FOLDER"] = "item.menu.delete.folder";
    IntentEvent["LIBRARY_ITEM_MENU_MOVE_FOLDER"] = "item.menu.move.folder";
    /** @libraryInformationModal */
    IntentEvent["LIBRARY_INFORMATION_MODAL_CLOSE"] = "modal.library.information.close";
    IntentEvent["LIBRARY_INFORMATION_MODAL_OPEN"] = "modal.library.information.open";
    /** @preview */
    IntentEvent["PREVIEW_DOWNLOAD_MEDIA"] = "preview.download";
    /** @upload */
    IntentEvent["UPLOAD_MEDIA_CANCEL"] = "upload.media.cancel";
    IntentEvent["UPLOAD_MEDIAS_CANCEL"] = "upload.medias.cancel";
    IntentEvent["UPLOAD_MEDIA_RETRY"] = "upload.media.retry";
    IntentEvent["UPLOAD_ARTICLE_CLICK"] = "upload.article.click";
    /** @settings */
    IntentEvent["SETTINGS_MEDIA_SHARE_COPY_LINK"] = "settings.media.share.copy.link";
    IntentEvent["SETTINGS_MEDIA_SHARE_COPY_EMBED"] = "settings.media.share.copy.embed";
    IntentEvent["SETTINGS_MEDIA_SUBTITLE_UPLOAD"] = "settings.media.subtitle.upload";
    IntentEvent["SETTINGS_MEDIA_SUBTITLE_DELETE"] = "settings.media.subtitle.delete";
    IntentEvent["SETTINGS_MEDIA_SUBTITLE_ACTIVE"] = "settings.media.subtitle.active";
    IntentEvent["SETTINGS_MEDIA_SUBTITLE_INACTIVE"] = "settings.media.subtitle.inactive";
    IntentEvent["SETTINGS_MEDIA_THUMB_UPLOAD"] = "settings.media.thumb.upload";
    IntentEvent["SETTINGS_MEDIA_RESTRICTION"] = "settings.media.restriction";
    IntentEvent["SETTINGS_MEDIA_RESTRICTIONS_MULTIPLE"] = "settings.media.restriction.multiple";
    IntentEvent["SETTINGS_MEDIA_CONTROLS_CHAPTERS"] = "settings.media.controls.chapters";
    /** @page */
    IntentEvent["PAGE_CHANGE_ACCOUNT"] = "go.to.change.account";
    IntentEvent["PAGE_PROFILE"] = "go.to.profile";
    IntentEvent["PAGE_HELP"] = "go.to.help";
    IntentEvent["PAGE_SIGNATURE"] = "go.to.signature";
    IntentEvent["PAGE_HOME"] = "go.to.home";
    IntentEvent["PAGE_LIBRARY"] = "go.to.library";
    IntentEvent["PAGE_ANALYTICS"] = "go.to.analytics";
    /** @importbyprovider  */
    IntentEvent["IMPORT_PANDA"] = "modal.import.panda";
    IntentEvent["IMPORT_PANDA_SEND"] = "modal.import.panda.send";
    IntentEvent["IMPORT_PANDA_SEND_ERROR_API_KEY_INVALID"] = "modal.import.panda.error.api_key_invalid";
    IntentEvent["IMPORT_PANDA_SEND_ERROR_IMPORT_IN_PROGRESS"] = "modal.import.panda.error.import_in_progress";
    IntentEvent["IMPORT_PANDA_RETRY"] = "modal.import.panda.retry";
    IntentEvent["IMPORT_VIMEO"] = "modal.import.vimeo";
    IntentEvent["IMPORT_VIMEO_SEND_ERROR_API_KEY_INVALID"] = "modal.import.vimeo.error.api_key_invalid";
    IntentEvent["IMPORT_VIMEO_SEND_ERROR_IMPORT_IN_PROGRESS"] = "modal.import.vimeo.error.import_in_progress";
    IntentEvent["IMPORT_VIMEO_SEND"] = "modal.import.vimeo.send";
    IntentEvent["IMPORT_VIMEO_RETRY"] = "modal.import.vimeo.retry";
    IntentEvent["IMPORT_GOOGLE_DRIVE"] = "modal.import.google_drive";
    IntentEvent["IMPORT_GOOGLE_DRIVE_CONFIRMATION"] = "modal.import.google_drive.confirmation";
    IntentEvent["IMPORT_YOUTUBE"] = "modal.import.youtube";
    IntentEvent["IMPORT_YOUTUBE_CONFIRMATION"] = "modal.import.youtube.confirmation";
    /** @modalAdditionalContent */
    IntentEvent["ADDITIONAL_CONTENT_MODAL_CLOSE"] = "modal.additional.content.close";
    IntentEvent["ADDITIONAL_CONTENT_MODAL_CLICK_CLOSE_BUTTON"] = "modal.additional.content.close.button";
    IntentEvent["ADDITIONAL_CONTENT_MODAL_OPEN"] = "modal.additional.content.open";
    IntentEvent["OPEN_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX"] = "modal.fakedoor.additional.content.open";
    IntentEvent["CLOSE_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX"] = "modal.fakedoor.additional.content.close";
    IntentEvent["MSG_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX"] = "modal.fakedoor.additional.content.msg";
    IntentEvent["ADDITIONAL_CONTENT_OPEN_SUBTITLES"] = "modal.additional.subtitles.open";
    IntentEvent["ADDITIONAL_CONTENT_LANGUAGES_SUBTITLES"] = "modal.additional.subtitles.languages";
    IntentEvent["ADDITIONAL_CONTENT_LANGUAGES_SUBTITLES_RETRY"] = "modal.additional.subtitles.languages.retry";
    IntentEvent["ADDITIONAL_CONTENT_SUBTITLES_RETURN"] = "modal.additional.subtitles.return";
    IntentEvent["ADDITIONAL_CONTENT_CLICK_GENERATE_SUBTITLES"] = "modal.additional.subtitles.generate.click";
    IntentEvent["ADDITIONAL_CONTENT_UNDERSTAND_CLICK_SUBTITLES"] = "modal.additional.subtitles.understand.click";
    IntentEvent["ADDITIONAL_CONTENT_ERROR_CLOSE_SUBTITLES"] = "modal.additional.subtitles.error.close";
    IntentEvent["ADDITIONAL_CONTENT_ERROR_RETRY_SUBTITLES"] = "modal.additional.subtitles.error.retry";
})(IntentEvent || (IntentEvent = {}));
export const IntentEventImportProvider = {
    IMPORT: {
        [ImportProvider.PANDA]: {
            BUTTON: IntentEvent.IMPORT_PANDA,
            ERROR: {
                API_KEY_INVALID: IntentEvent.IMPORT_PANDA_SEND_ERROR_API_KEY_INVALID,
                IMPORT_IN_PROGRESS: IntentEvent.IMPORT_PANDA_SEND_ERROR_IMPORT_IN_PROGRESS,
            },
            SEND: IntentEvent.IMPORT_PANDA_SEND,
            RETRY: IntentEvent.IMPORT_PANDA_RETRY,
        },
        [ImportProvider.VIMEO]: {
            BUTTON: IntentEvent.IMPORT_VIMEO,
            ERROR: {
                API_KEY_INVALID: IntentEvent.IMPORT_VIMEO_SEND_ERROR_API_KEY_INVALID,
                IMPORT_IN_PROGRESS: IntentEvent.IMPORT_VIMEO_SEND_ERROR_IMPORT_IN_PROGRESS,
            },
            SEND: IntentEvent.IMPORT_VIMEO_SEND,
            RETRY: IntentEvent.IMPORT_VIMEO_RETRY,
        },
    },
    FAKEDOOR: {
        [ImportProvider.GOOGLE_DRIVE]: {
            BUTTON: IntentEvent.IMPORT_GOOGLE_DRIVE,
            CONFIRMATION: IntentEvent.IMPORT_GOOGLE_DRIVE_CONFIRMATION,
        },
        [ImportProvider.YOUTUBE]: {
            BUTTON: IntentEvent.IMPORT_YOUTUBE,
            CONFIRMATION: IntentEvent.IMPORT_YOUTUBE_CONFIRMATION,
        },
    },
};
