import Api from 'src/infrastructure/services/Api';
const deleteSubtitle = async ({ mediaCode, subtitleCode }) => {
    const response = await Api.call({
        method: 'DELETE',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/subtitle/${subtitleCode}`,
    });
    return response.data;
};
const download = async ({ mediaCode, subtitleCode }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/subtitle/${subtitleCode}/download`,
    });
    return response.data;
};
const enable = async ({ mediaCode, subtitleCode, subtitleStatus, }) => {
    const response = await Api.call({
        method: 'PUT',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/subtitle/${subtitleCode}`,
        data: {
            active: subtitleStatus,
        },
    });
    return response.data;
};
const list = async ({ mediaCode }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/subtitle`,
    });
    return response.data;
};
const supported = async () => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/subtitle/supported-languages`,
    });
    return response.data;
};
const translate = async ({ mediaCode, subtitleCode, language }) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/${subtitleCode}/translate/${language}`,
    });
    return response.data;
};
const batchTranslate = async ({ targetLanguages, mediaCodes, sourceLanguage, }) => {
    return Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/batch/translate`,
        data: {
            targetLanguage: targetLanguages,
            mediaCodes,
            sourceLanguage,
        },
    });
};
const transcribe = async ({ mediaCode }) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/${mediaCode}/transcribe`,
    });
    return response.data;
};
const listAllSubtitleGenerationBatches = async () => {
    const { data } = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/media/batch/status`,
    });
    return data;
};
const retrySubtitleGenerationBatch = async ({ batchId, }) => {
    return Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/media/batch/${batchId}`,
    });
};
export { deleteSubtitle, download, enable, list, supported, translate, batchTranslate, transcribe, listAllSubtitleGenerationBatches, retrySubtitleGenerationBatch, };
