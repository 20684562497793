import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import styles from '../styles.module.scss';
import { AdditionalContentCard } from '../../../AdditionalContentCard';
import { FakeDoor } from '../FakeDoor';
export const Home = ({ additionalContentItems }) => {
    const { t } = useTranslation();
    const openFakeDoorModal = ({ modalName, title, description, }) => {
        NiceModal.show(FakeDoor, {
            modalName,
            title,
            description,
        });
        EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.OPEN_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX}.${modalName}`);
    };
    return (_jsx("div", { slot: 'content', children: _jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.contentHeader, children: [_jsx(Heading, { type: 'h2', size: 'small', className: styles.modalContentTitle, children: t('additional_content.modal.content_title') }), _jsx("p", { className: styles.contentDescription, children: t('additional_content.modal.description') })] }), _jsx("div", { className: styles.additionalContentItemsContainer, children: Object.keys(additionalContentItems).map((key) => (_jsx(AdditionalContentCard, { title: t(`additional_content.modal.items.${key}.title`), description: t(`additional_content.modal.items.${key}.description`), icon: additionalContentItems[key].icon, onClick: () => additionalContentItems[key].onClick
                            ? additionalContentItems[key].onClick()
                            : openFakeDoorModal({
                                modalName: key,
                                title: t(`additional_content.fake_door.items.${key}.title`),
                                description: t(`additional_content.fake_door.items.${key}.description`),
                            }) }, key))) })] }) }));
};
