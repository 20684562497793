import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { FormField } from 'src/ui/cosmosWeb/FormField';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/application/hooks/useAuth';
import styles from './styles.module.scss';
const MIN_MSG_LENGTH = 5;
const MAX_MSG_LENGTH = 280;
export const FakeDoor = NiceModal.create(({ modalName, title, description }) => {
    const modal = useModal();
    const [message, setMessage] = useState('');
    const { user } = useAuth();
    const { t } = useTranslation();
    const handleClickSendMessage = () => {
        modal.hide();
        EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.MSG_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX}.${modalName}`, 'input', [
            {
                key: 'user',
                value: user.profile.id,
            },
            {
                key: 'message',
                value: message,
            },
        ]);
    };
    return (_jsxs(Modal, { opened: modal.visible, className: styles.container, onCloseModal: () => {
            modal.remove();
            EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.CLOSE_FAKEDOOR_ADDITIONAL_CONTENT_PREFIX}.${modalName}`);
        }, children: [_jsx(Heading, { type: 'h4', className: styles.modalTitle, slot: 'heading', children: title }), _jsxs("div", { slot: 'description', children: [_jsx("p", { className: styles.description, children: description }), _jsx("div", { children: _jsxs(FormField, { children: [_jsx("label", { htmlFor: 'fake-door-additional-content-message', slot: 'label', children: t('additional_content.fake_door.label_input') }), _jsx("textarea", { id: 'fake-door-additional-content-message', "data-testid": 'fake-door-additional-content-message', className: styles.inputMessage, rows: 3, onChange: (e) => setMessage(e.target.value) }), _jsx("div", { slot: 'support-message', children: t('additional_content.fake_door.max_characters_description', {
                                        count: MAX_MSG_LENGTH,
                                    }) })] }) })] }), _jsx(Button, { slot: 'controls', onClick: handleClickSendMessage, className: styles.submitButton, disabled: message.length < MIN_MSG_LENGTH, "data-testid": 'fake-door-additional-content-button-submit', children: t('additional_content.fake_door.send_button') })] }));
});
