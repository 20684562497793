// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u5Rz9NoMj7epDF2k7fQT.dagIPvnKmsutZPv75v9I{pointer-events:none;opacity:40%}`, "",{"version":3,"sources":["webpack://./src/ui/cosmos/SelectMultiple/SelectMultipleOption/styles.module.scss"],"names":[],"mappings":"AACE,2CACE,mBAAA,CACA,WAAA","sourcesContent":[".selectMultipleOption {\n  &.disabled {\n    pointer-events: none;\n    opacity: 40%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectMultipleOption": `u5Rz9NoMj7epDF2k7fQT`,
	"disabled": `dagIPvnKmsutZPv75v9I`
};
export default ___CSS_LOADER_EXPORT___;
