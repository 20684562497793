// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VLMzpH28m_dSJqIKt67d{border-radius:var(--hc-size-border-large);height:4.375rem}.VLMzpH28m_dSJqIKt67d .qzfZUq1XX7vVAqJxMfsK{height:4.375rem;border-radius:var(--hc-size-border-large);width:100%}.VLMzpH28m_dSJqIKt67d:last-child{margin-bottom:0 !important}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/MediaUpload/components/ImportMediaProvider/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CACA,eAAA,CAEA,4CACE,eAAA,CACA,yCAAA,CACA,UAAA,CAGF,iCACE,0BAAA","sourcesContent":[".info {\n  border-radius: var(--hc-size-border-large);\n  height: 4.375rem;\n\n  .box {\n    height: 4.375rem;\n    border-radius: var(--hc-size-border-large);\n    width: 100%;\n  }\n\n  &:last-child {\n    margin-bottom: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `VLMzpH28m_dSJqIKt67d`,
	"box": `qzfZUq1XX7vVAqJxMfsK`
};
export default ___CSS_LOADER_EXPORT___;
