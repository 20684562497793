import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Error from 'src/ui/TransferStateManager/error';
import Optimizing from 'src/ui/TransferStateManager/optimizing';
import Success from 'src/ui/TransferStateManager/success';
import { SubtitleBatchStatus, } from 'src/infrastructure/endpoints/subtitle/interfaces';
import { useTranslation } from 'react-i18next';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { ability } from 'src/infrastructure/libs/CASL';
// eslint-disable-next-line max-len
import { useShowSubtitleBatchFeedbackModal } from '../SubtitleBatchContainer/SubtitleBatchFeedbackModal/hooks/useShowSubtitleBatchFeedbackModal';
import { useSubtitleBatches } from '../../hooks/useSubtitleBatches';
import styles from './styles.module.scss';
const SubtitleGenerationBatchItemStates = {
    [SubtitleBatchStatus.FAILED]: Error,
    [SubtitleBatchStatus.PROCESSING]: Optimizing,
    [SubtitleBatchStatus.COMPLETE]: Success,
};
const statusLabelPrefix = 'additional_content.modal.subtitle.status';
export const SubtitleBatchItems = () => {
    const { t } = useTranslation();
    const { listSubtitle: { data }, } = useSubtitleBatches();
    const feedbackModal = useShowSubtitleBatchFeedbackModal();
    const { retrySubtitlesFailed } = useSubtitleBatches();
    const handleClickToRetry = async ({ batchId, }) => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_LANGUAGES_SUBTITLES_RETRY, 'button', [
            {
                key: 'batchId',
                value: batchId,
            },
        ]);
        try {
            await retrySubtitlesFailed.mutateAsync({
                batchId,
            });
            feedbackModal.showSuccessMessage();
        }
        catch {
            feedbackModal.showErrorMessage(() => handleClickToRetry({ batchId }));
        }
    };
    return (_jsx(_Fragment, { children: data?.map((item) => {
            const UploadItemStateComponent = SubtitleGenerationBatchItemStates[item.status];
            return (_jsx("div", { className: styles.info, "data-testid": 'subtitle-batch-items', children: _jsx("div", { className: styles.box, children: UploadItemStateComponent && (_jsx(UploadItemStateComponent, { item: {
                            title: t(`${statusLabelPrefix}.${item.status.toLowerCase()}.title`),
                            ...(item.status === SubtitleBatchStatus.FAILED && {
                                errorMessage: t('upload.upload_status.error.message'),
                                ...(ability.can('edit', 'Media') && {
                                    onClickToRetry: () => {
                                        handleClickToRetry({
                                            batchId: item.batchProcessId,
                                        });
                                    },
                                }),
                            }),
                        } })) }) }, item.batchProcessId));
        }) }));
};
