import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'src/ui/cosmosWeb/Card';
import { FontAwesomeIcon, faPen, } from 'src/application/icons/fontAwesome/light';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const AdditionalContentCard = ({ icon, title, description, onClick, }) => {
    const { t } = useTranslation();
    return (_jsxs(Card, { className: styles.container, "data-testid": 'additional-content-card', children: [_jsxs("header", { className: styles.header, slot: 'heading', children: [_jsx("span", { className: styles.cardIcon, children: _jsx(FontAwesomeIcon, { icon: icon }) }), _jsx(Heading, { type: 'h4', size: 'micro', children: title })] }), _jsx("p", { slot: 'content', className: styles.description, children: description }), _jsx("div", { className: styles.buttonContent, children: _jsxs(Button, { "data-testid": 'additional-content-card-button', onClick: onClick, iconPosition: 'end', className: styles.buttonCreateNow, slot: 'controls', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faPen }) }), t('additional_content.modal.create_now')] }) })] }));
};
