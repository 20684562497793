import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Loading } from 'src/ui/cosmos/Loading';
import { Can } from 'src/infrastructure/libs/CASL/can';
import { useTranslation } from 'react-i18next';
import { EmptyAlert } from './EmptyAlert';
import styles from './styles.module.scss';
import { useGetFolders } from './hooks/useGetFolders';
import { useMoveItems } from './hooks/useMoveItems';
import { useFolderNavigation } from './hooks/useFolderNavigation';
import { MoveModalHeader } from './MoveModalHeader';
import { MoveModalListItems } from './MoveModalListItems';
export const MoveModal = NiceModal.create(({ itemsToMove }) => {
    const [selectedFolder, setSelectedFolder] = useState();
    const infiniteScrollListEvents = useRef(null);
    const modal = useModal();
    const { t } = useTranslation();
    const { moveItem } = useMoveItems();
    const { currentFolder, isThereAnyFolderOpen, openFolder, goBackToParentFolder, } = useFolderNavigation();
    useEffect(() => {
        setSelectedFolder(currentFolder);
    }, [currentFolder]);
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetFolders({
        folder: currentFolder,
        isThereAnyFolderOpen,
    });
    const defaultFolder = useMemo(() => ({
        code: data?.pages?.[0].folder.code,
        name: t('library.title'),
    }), [data?.pages, t]);
    const folderDetailsFromFirstItemToMove = itemsToMove[0].belongsToFolder;
    const itemsToMoveBelongToFolder = {
        code: folderDetailsFromFirstItemToMove?.code,
        name: folderDetailsFromFirstItemToMove?.code === defaultFolder.code
            ? defaultFolder.name
            : folderDetailsFromFirstItemToMove?.name,
    };
    const areItemsToBeMovedBelongSameFolder = useMemo(() => itemsToMove.every((item) => item.belongsToFolder?.code === itemsToMoveBelongToFolder?.code), [itemsToMoveBelongToFolder?.code, itemsToMove]);
    const allItemsLoaded = useMemo(() => {
        const items = data?.pages?.reduce((page, contents) => page.concat(contents.content), []) || [];
        if (!isThereAnyFolderOpen &&
            itemsToMove.every((item) => item.belongsToFolder?.code !== defaultFolder.code)) {
            items.unshift({
                ...defaultFolder,
                category: 'folder',
            });
        }
        return items;
    }, [data?.pages, defaultFolder, isThereAnyFolderOpen, itemsToMove]);
    const handleMoveMedia = async () => {
        await moveItem.mutateAsync({
            folderDestination: selectedFolder,
            items: itemsToMove,
        });
        modal.hide();
    };
    return (_jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(Modal, { onCloseModal: modal.remove, className: styles.modal, dismissible: false, opened: modal.visible, children: [_jsx(MoveModalHeader, { itemsToMove: itemsToMove, currentFolderName: isThereAnyFolderOpen ? currentFolder.name : '', goBackToParentFolder: () => {
                        goBackToParentFolder();
                        infiniteScrollListEvents.current?.scrollToTop();
                    }, itemsToBeMovedBelongToFolderWithName: areItemsToBeMovedBelongSameFolder
                        ? itemsToMoveBelongToFolder.name
                        : '' }), _jsxs("div", { className: styles.content, slot: 'description', children: [isLoading && _jsx(Loading, { full: true }), allItemsLoaded.length === 0 && (_jsx(EmptyAlert, { text: t('library.modal.move_medias.empty_folder') })), allItemsLoaded.length > 0 && (_jsx(MoveModalListItems, { ref: infiniteScrollListEvents, allItemsLoaded: allItemsLoaded, isRowLoaded: !hasNextPage, itemsToMove: itemsToMove, loadMoreRows: () => hasNextPage && !isFetchingNextPage && fetchNextPage(), onOpenFolder: (folder) => {
                                openFolder(folder);
                                infiniteScrollListEvents.current?.scrollToTop();
                            }, onSelectFolder: setSelectedFolder, selectedFolder: selectedFolder }))] }), _jsx(Button, { onClick: modal.hide, slot: 'controls', variation: 'tertiary', context: 'neutral', children: t('general.cancel') }), _jsx(Button, { onClick: handleMoveMedia, disabled: !selectedFolder ||
                        itemsToMove.some((itemToMove) => itemToMove?.belongsToFolder?.code === selectedFolder.code), slot: 'controls', "data-testid": 'move-content-button', children: t('general.move') })] }) }));
});
