// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sxzMRms5I_3rjeV8MCXw{--hc-modal-dialog-width: 32.5rem;--hc-modal-footer-justify-content: center;--hc-modal-dialog-max-height: 30rem;--hc-modal-content-padding: 0 var(--hc-size-spacing-5) 0 var(--hc-size-spacing-5);--hc-modal-footer-padding: 0 var(--hc-size-spacing-5) var(--hc-size-spacing-5);--hc-h-base-lg-font-size: var(--hc-size-text-extralarge)}.sxzMRms5I_3rjeV8MCXw .GCrdNViZRDPpP0N4bzGJ{color:var(--gray-700);--hc-h-margin: 0;width:26rem}.sxzMRms5I_3rjeV8MCXw .H9lkHhfbrdk2RPyzWF_P{font-size:var(--hc-size-text-medium);color:var(--gray-500)}.sxzMRms5I_3rjeV8MCXw .HjjfnJiRouMeHa5fACiN{min-height:6.5rem !important}.sxzMRms5I_3rjeV8MCXw .fZlAjVWls35WcXmlRMJI{width:100%}`, "",{"version":3,"sources":["webpack://./src/domain/Media/components/Modal/AdditionalContent/FakeDoor/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gCAAA,CACA,yCAAA,CACA,mCAAA,CACA,iFAAA,CACA,8EAAA,CACA,wDAAA,CAEA,4CACE,qBAAA,CACA,gBAAA,CACA,WAAA,CAGF,4CACE,oCAAA,CACA,qBAAA,CAGH,4CACG,4BAAA,CAGF,4CACE,UAAA","sourcesContent":[".container {\n  --hc-modal-dialog-width: 32.5rem;\n  --hc-modal-footer-justify-content: center;\n  --hc-modal-dialog-max-height: 30rem;\n  --hc-modal-content-padding: 0 var(--hc-size-spacing-5) 0 var(--hc-size-spacing-5); \n  --hc-modal-footer-padding: 0 var(--hc-size-spacing-5) var(--hc-size-spacing-5);\n  --hc-h-base-lg-font-size: var(--hc-size-text-extralarge);\n\n  .modalTitle {\n    color: var(--gray-700); \n    --hc-h-margin: 0;\n    width: 26rem;\n  }\n\n  .description {\n    font-size: var(--hc-size-text-medium);\n    color: var(--gray-500);\n  }\n\n .inputMessage {\n    min-height: 6.5rem !important;\n  }\n\n  .submitButton {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sxzMRms5I_3rjeV8MCXw`,
	"modalTitle": `GCrdNViZRDPpP0N4bzGJ`,
	"description": `H9lkHhfbrdk2RPyzWF_P`,
	"inputMessage": `HjjfnJiRouMeHa5fACiN`,
	"submitButton": `fZlAjVWls35WcXmlRMJI`
};
export default ___CSS_LOADER_EXPORT___;
