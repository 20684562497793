import { debounce } from 'src/utils/debounce';
import * as SubtitleEndpoint from 'src/infrastructure/endpoints/subtitle';
const DEBOUNCE_TIME = 500;
class SubtitleService {
    constructor() {
        Object.defineProperty(this, "enable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(this.handleEnableDebounce, DEBOUNCE_TIME)
        });
    }
    async delete({ mediaCode, subtitleCode }) {
        return SubtitleEndpoint.deleteSubtitle({ mediaCode, subtitleCode });
    }
    async download({ mediaCode, subtitleCode, subtitleStatus }) {
        return SubtitleEndpoint.download({
            mediaCode,
            subtitleCode,
            subtitleStatus,
        });
    }
    async handleEnableDebounce({ mediaCode, subtitleCode, subtitleStatus, }) {
        return SubtitleEndpoint.enable({ mediaCode, subtitleCode, subtitleStatus });
    }
    async list({ mediaCode }) {
        return SubtitleEndpoint.list({ mediaCode });
    }
    async supported() {
        return SubtitleEndpoint.supported();
    }
    async translate({ mediaCode, subtitleCode, language, }) {
        return SubtitleEndpoint.translate({
            mediaCode,
            subtitleCode,
            language,
        });
    }
    async batchTranslate({ targetLanguages, mediaCodes, sourceLanguage, }) {
        return SubtitleEndpoint.batchTranslate({
            targetLanguages,
            mediaCodes,
            sourceLanguage,
        });
    }
    async transcribe({ mediaCode }) {
        return SubtitleEndpoint.transcribe({
            mediaCode,
        });
    }
    async listAllSubtitleGenerationBatches() {
        return SubtitleEndpoint.listAllSubtitleGenerationBatches();
    }
    async retrySubtitleGenerationBatch({ batchId, }) {
        return SubtitleEndpoint.retrySubtitleGenerationBatch({
            batchId,
        });
    }
}
export default new SubtitleService();
