import { faSubtitles, faChartTreeMap, faSquareQuestion, faClosedCaptioning, } from 'src/application/icons/fontAwesome/light';
export const additionalContentItems = {
    subtitle: {
        icon: faSubtitles,
    },
    chapters: {
        icon: faChartTreeMap,
    },
    quiz: {
        icon: faSquareQuestion,
    },
    transcription_search: {
        icon: faClosedCaptioning,
    },
};
