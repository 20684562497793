import { useEffect } from 'react';
export function useSelectEventListeners(selectRef, { onChange, onFocus, onOpen, onClose }) {
    useEffect(() => {
        const select = selectRef.current;
        if (!select)
            return;
        const eventHandlers = [
            { event: 'change', handler: onChange },
            { event: 'focus', handler: onFocus },
            { event: 'open', handler: onOpen },
            { event: 'close', handler: onClose },
        ];
        eventHandlers.forEach(({ event, handler }) => {
            select.addEventListener(event, handler);
        });
        return () => {
            eventHandlers.forEach(({ event, handler }) => {
                select.removeEventListener(event, handler);
            });
        };
    }, [selectRef, onFocus, onOpen, onClose, onChange]);
}
