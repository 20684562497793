import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useMediaDurationCount } from 'src/domain/Media/hooks';
import usePlan from 'src/domain/User/Plan/hooks/usePlan';
import { convertSizeUnity } from 'src/domain/User/Plan/utils';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import styles from './styles.module.scss';
export const FreemiumLayout = () => {
    const { t } = useTranslation();
    const { data: plan, isLoading: isPlanLoading } = usePlan();
    const { data: mediaDuration, isLoading: isMediaDurationLoading } = useMediaDurationCount();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.row, children: [_jsxs("div", { children: [_jsx("span", { className: styles.rowTitle, children: t('plan.info.storage') }), isPlanLoading ? (_jsx(ContentLoader, { "data-testid": 'content-loader', className: styles.contentLoader })) : (_jsx("strong", { children: convertSizeUnity(plan?.storageConsumption ?? 0) }))] }), _jsxs("div", { children: [_jsx("span", { className: styles.rowTitle, children: t('home.totalizers.total_medias_time') }), isMediaDurationLoading ? (_jsx(ContentLoader, { "data-testid": 'content-loader', className: styles.contentLoader })) : (_jsx("strong", { children: t('home.totalizers.minutes', {
                                    amount: mediaDuration.total ?? 0,
                                }) }))] })] }), _jsx("span", { className: styles.separator })] }));
};
