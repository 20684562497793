import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
export function SelectMultipleOption({ value, children, selected, disabled = false, }) {
    const selectOptionRef = useRef(null);
    useEffect(() => {
        if (!selectOptionRef.current) {
            return;
        }
        const forceUncheckInputsDisabled = () => {
            const inputs = selectOptionRef.current?.shadowRoot?.querySelectorAll('input[type="checkbox"]:checked');
            if (disabled && inputs?.length > 0) {
                inputs.forEach((input) => (input.checked = false));
                selectOptionRef.current.removeAttribute('selected');
            }
        };
        forceUncheckInputsDisabled();
    }, [disabled]);
    return (_jsx("hot-select-multiple-option", { ref: selectOptionRef, value: value, selected: selected, class: classNames(styles.selectMultipleOption, {
            [styles.disabled]: disabled,
        }), "data-testid": `hot-select-multiple-option-${value}`, children: children }));
}
