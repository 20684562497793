import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { faArrowDownToBracket, faFolderArrowUp, faLock, faTrashCan, faChartSimple, faCircleInfo, faPuzzle, } from 'src/application/icons/fontAwesome/light';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { useMediaDownload } from 'src/domain/Media/hooks';
import { useDeleteItems } from 'src/domain/Folder/hooks';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { MoveModal } from 'src/domain/Folder/containers/Modal/MoveModal';
import { useDetailsFolder } from 'src/domain/Folder/hooks/useDetailsFolder';
import { useDetailsMedia } from 'src/domain/Media/hooks/useDetailsMedia';
import { isMedia } from 'src/domain/Media/utils/isMedia';
import { DomainManagerDrawer } from 'src/domain/Media/modules/Domain/components/DomainManagerDrawer';
import { Can } from 'src/infrastructure/libs/CASL/can';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AdditionalContentModal } from 'src/domain/Media/components/Modal/AdditionalContent';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import styles from './styles.module.scss';
const ActionsContainer = ({ selectedItems }) => {
    const { isEnabled } = useFeatureToggle();
    const isAdditionalContentEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_ADDITIONAL_CONTENT);
    const navigate = useNavigate();
    const location = useLocation();
    const downloadMedia = useMediaDownload();
    const { showDetailsFolder } = useDetailsFolder();
    const { showDetailsMedia } = useDetailsMedia();
    const { t } = useTranslation();
    const deleteItems = useDeleteItems();
    const { mediasSelected, foldersSelected } = selectedItems.reduce((previous, current) => {
        if (isMedia(current)) {
            previous.mediasSelected.push(current);
        }
        else {
            previous.foldersSelected.push(current);
        }
        return previous;
    }, {
        mediasSelected: [],
        foldersSelected: [],
    });
    const hasOnlyMediasSelected = mediasSelected.length > 0 && foldersSelected.length === 0;
    const hasOnlyOneMediaSelected = foldersSelected.length === 0 && mediasSelected.length === 1;
    const hasOnlyOneFolderSelected = foldersSelected.length === 1 && mediasSelected.length === 0;
    const hasOnlyOneMediaOrFolderSelected = hasOnlyOneFolderSelected || hasOnlyOneMediaSelected;
    const hasAtLeastOneMediaOrFolderSelected = foldersSelected.length >= 1 || mediasSelected.length >= 1;
    const shouldShowDeleteAction = useMemo(() => {
        return hasAtLeastOneMediaOrFolderSelected;
    }, [hasAtLeastOneMediaOrFolderSelected]);
    const shouldShowDownloadAction = useMemo(() => {
        return hasOnlyOneMediaSelected;
    }, [hasOnlyOneMediaSelected]);
    const shouldShowRestrictionAction = useMemo(() => {
        return hasOnlyMediasSelected;
    }, [hasOnlyMediasSelected]);
    const shouldShowAdditionalContent = hasOnlyMediasSelected && isAdditionalContentEnabled;
    const shouldShowAnalyticsAction = useMemo(() => {
        return hasOnlyOneMediaSelected;
    }, [hasOnlyOneMediaSelected]);
    const handleDeleteItems = useCallback(() => {
        return deleteItems(selectedItems);
    }, [deleteItems, selectedItems]);
    const handleMediaAnalytics = useCallback(() => {
        const { code } = mediasSelected[0];
        navigate(`/library/media/${code}`, {
            state: {
                referrer: `${location.pathname}${location.search}`,
            },
        });
    }, [location.pathname, location.search, mediasSelected, navigate]);
    const handleDetails = () => {
        hasOnlyOneFolderSelected
            ? showDetailsFolder(foldersSelected[0].code)
            : showDetailsMedia(mediasSelected[0].code);
    };
    return (_jsxs("div", { className: styles.container, children: [shouldShowAnalyticsAction && (_jsx(Can, { I: 'list', a: 'Media', children: _jsx(Tooltip, { text: t('analytics.page.title'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-analytics', context: 'neutral', variation: 'tertiary', icon: faChartSimple, onClick: handleMediaAnalytics }) }) })), hasOnlyOneMediaOrFolderSelected && (_jsx(Can, { I: 'list', a: 'Media', children: _jsx(Tooltip, { text: t('general.details'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-details', context: 'neutral', variation: 'tertiary', icon: faCircleInfo, onClick: handleDetails }) }) })), _jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Tooltip, { text: t('general.move_to'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-move', context: 'neutral', variation: 'tertiary', icon: faFolderArrowUp, onClick: () => NiceModal.show(MoveModal, {
                            itemsToMove: [...selectedItems],
                        }) }) }) }), shouldShowRestrictionAction && (_jsx(Can, { I: 'domain', a: 'Media', children: _jsx(Tooltip, { text: t('general.restrictions'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-restriction', context: 'neutral', variation: 'tertiary', icon: faLock, onClick: () => NiceModal.show(DomainManagerDrawer, {
                            medias: selectedItems,
                        }) }) }) })), shouldShowAdditionalContent && (_jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Tooltip, { text: t('additional_content.modal.header_title'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-additional-content', context: 'neutral', variation: 'tertiary', icon: faPuzzle, onClick: () => {
                            NiceModal.show(AdditionalContentModal, {
                                medias: selectedItems,
                            });
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_MODAL_OPEN);
                        } }) }) })), shouldShowDownloadAction && (_jsx(Can, { I: 'download', a: 'Media', children: _jsx(Tooltip, { text: t('general.make_download'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-download', onClick: () => downloadMedia(mediasSelected[0].code), context: 'neutral', variation: 'tertiary', icon: faArrowDownToBracket }) }) })), shouldShowDeleteAction && (_jsx(Can, { I: 'delete', a: 'Media', children: _jsx(Tooltip, { text: t('general.remove'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-delete', onClick: handleDeleteItems, context: 'neutral', variation: 'tertiary', icon: faTrashCan }) }) }))] }));
};
export default ActionsContainer;
