import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ErrorComponent from 'src/ui/TransferStateManager/error';
import Optimizing from 'src/ui/TransferStateManager/optimizing';
import Queue from 'src/ui/TransferStateManager/queue';
import Success from 'src/ui/TransferStateManager/success';
import { useTranslation } from 'react-i18next';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import Progress from 'src/ui/TransferStateManager/progress';
import { useModal } from 'src/infrastructure/libs/NiceModal';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import { useFileUploadProcess } from '../../contexts/MediaUploadContext/hooks/useFileUploadProcess';
import { FileErrorType, FileStatus, } from '../../interfaces/FileUploadProps';
import { useRemoveFileOnUpload } from '../../contexts/MediaUploadContext/hooks/useRemoveFileOnUpload';
import styles from './styles.module.scss';
const UploadItemStates = {
    [FileStatus.FATAL_ERROR]: ErrorComponent,
    [FileStatus.ERROR]: ErrorComponent,
    [FileStatus.OPTIMIZING]: Optimizing,
    [FileStatus.PROGRESS]: Progress,
    [FileStatus.QUEUE]: Queue,
    [FileStatus.SUCCESS]: Success,
};
export const FileUpload = () => {
    const { t } = useTranslation();
    const confirmationModal = useModal(BasicConfirmationModal);
    const { files, runUpload } = useFileUploadProcess();
    const { fileUploadingDelete } = useRemoveFileOnUpload();
    const confirmFileUploadingDelete = async () => {
        const confirmed = await confirmationModal.show({
            title: t('modal.interrupt_upload.one.title'),
            description: t('modal.interrupt_upload.one.description'),
            buttons: {
                yes: {
                    title: t('modal.interrupt_upload.one.buttons.yes'),
                },
                no: {
                    title: t('modal.interrupt_upload.one.buttons.no'),
                    color: 'tertiary',
                },
            },
        });
        confirmationModal.hide();
        return !!confirmed;
    };
    const getMessageError = (errorType) => {
        const errorTypeMessage = {
            [FileErrorType.OVER_SIZE]: t('upload.upload_status.error.oversize'),
            [FileErrorType.UNSUPPORTED_FILE]: t('upload.upload_status.error.unsupported_file'),
        };
        const message = t('upload.upload_status.error.message');
        const messageDetails = errorType && errorTypeMessage[errorType]
            ? ` - ${errorTypeMessage[errorType]}`
            : '';
        return `${message}${messageDetails}`;
    };
    const handleRetryUpload = (item) => {
        runUpload(item);
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.UPLOAD_MEDIA_RETRY);
    };
    const buildUploadItemProps = (item) => {
        const itemData = {
            title: item.file.name,
            errorMessage: '',
        };
        if (item.status === FileStatus.ERROR ||
            item.status === FileStatus.FATAL_ERROR) {
            itemData['errorMessage'] = getMessageError(item.errorType);
            if (item.isValid && item.status === FileStatus.ERROR) {
                itemData['onClickToRetry'] = () => handleRetryUpload(item);
            }
        }
        if (item.status === FileStatus.PROGRESS) {
            itemData['progress'] = item.progress;
            if (item.canCancel) {
                itemData['onClickToCancel'] = () => fileUploadingDelete(item, confirmFileUploadingDelete);
            }
        }
        return itemData;
    };
    return (_jsx(_Fragment, { children: Object.values(files).map((item) => {
            const uploadItemProps = buildUploadItemProps(item);
            const UploadItemStateComponent = UploadItemStates[item.status];
            return (_jsx("div", { className: styles.info, children: _jsx("div", { className: styles.box, children: UploadItemStateComponent && (_jsx(UploadItemStateComponent, { item: uploadItemProps })) }) }, item.internalFileHash));
        }) }));
};
