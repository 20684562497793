import { useQuery, useMutation, useQueryClient, } from 'src/application/hooks/useRequest';
import SubtitleService from 'src/domain/Media/modules/Subtitle/services/SubtitleService';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
const REFETCH_INTERVAL = 2 * 1000 * 60; // 2 minutes
export const useSubtitleBatches = () => {
    const queryClient = useQueryClient();
    const listSubtitle = useQuery({
        queryKey: [QueryKeys.SUBTITLE_BATCH_GENERATION_STATUS],
        queryFn: () => SubtitleService.listAllSubtitleGenerationBatches(),
        refetchInterval: REFETCH_INTERVAL,
    });
    const retrySubtitlesFailed = useMutation({
        mutationFn: async ({ batchId }) => SubtitleService.retrySubtitleGenerationBatch({
            batchId,
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SUBTITLE_BATCH_GENERATION_STATUS],
            });
        },
    });
    const translateBatchSubtitle = useMutation({
        mutationFn: ({ targetLanguages, mediaCodes, sourceLanguage, }) => SubtitleService.batchTranslate({
            targetLanguages,
            mediaCodes,
            sourceLanguage,
        }),
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SUBTITLE, variables.mediaCodes],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.SUBTITLE_BATCH_GENERATION_STATUS],
            });
        },
    });
    return {
        listSubtitle,
        retrySubtitlesFailed,
        translateBatchSubtitle,
    };
};
